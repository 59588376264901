<template>
  <!-- blogs start -->
  <div class="blogs-page">
    <PageLoader />
    <!-- blog wrapper start -->
    <div class="blog-area-wrapper pt-5 pb-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 order-first order-lg-last">
            <h2 class="text-center mb-3">{{ about.title }}</h2>
            <div class="blog-wrapper-inner">
              <div class="single-blogg-item mb-30">
                <div class="blogg-content">
                  <div class="blog-desc mb-50" v-html="about.desc"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- blog wrapper end -->
  </div>
  <!-- blogs end -->
</template>
<script>
import PageLoader from "@/components/units/PageLoader.vue";
export default {
  name: "praivacy",
  components: {
    PageLoader
  },
  data() {
    return {
      about: []
    };
  },
  mounted() {
   window.scrollTo(0, 0)
    this.getData();
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    }
  },
  watch: {
    getFullPath() {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.loading = true;
      this.$http
        .get("pages/Privacy_Policy")
        .then(response => {
          this.about = response.data.data;
          response => (this.about = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
